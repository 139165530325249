// 埋め込みの場合、position: fixed や 高さ単位の vh がうまく機能しない（基準画面が iframe になってしまう）
// ので、デスクトップ版と同じパレットで emoji の大きさだけ大きくしたものを使用する。
// 埋め込み版は embed-theme-* というクラスが body 要素に付くのでその配下ならメディアクエリを適用しないようにする
.emoji-palette.for-reaction:not([class|="embed-theme"] *) {

    @media (max-width: 767px) {
        position: fixed;
        // JSで動的にパレットの幅と位置をいじっているんだけど、
        // スマホでは画面幅に一致させたいので important が必要
        right: auto!important;
        top: auto!important;
        bottom: 0!important;
        left: 0!important;
        width: 100vw !important;
        height: 70vh;
        animation: slideIn 0.3s ease;
    }

    & .main-section {
        @media (max-width: 767px)
        {
            height: auto;
            max-height: 95%;
        }
    }
}

.emoji-palette-background.for-reaction:not([class|="embed-theme"] *) {
    &.open {
        @media (max-width: 767px) {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background-color: rgba(0, 0, 0, .5);
            z-index: 99;
        }
    }
}
.emoji-palette-background.for-reaction {
    display: none;
}

.emoji-palette.for-reaction {
    position: absolute;
    background-color: #fff;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 250px;
    display: none;
    z-index: 100;

    &.open {
        display: block;
    }

    & ul.emoji-category-nav-tab {
        list-style: none;
        display: flex;
        justify-content: space-around;
        padding: 0;
        margin-bottom: 12px;

        & li:not(.active) {
            cursor: pointer;
        }

        & li.active {
            border-bottom: 2px solid #000000;
        }
    }

    & .main-section {
        max-height: 400px;
        overflow-y: auto;

        & .frequently-used {
            & p {
                font-size: 8px;
            }

            & ul {
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                padding: 0;

                & li {
                    margin: 2px;
                    cursor: pointer;
                }
            }
        }

        & .emoji-category-panels {
            & p {
                font-size: 8px;
            }

            & .emoji-panel {
                display: none;

                &.active {
                    display: block;
                }

                & ul {
                    list-style: none;
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    padding: 0;

                    & li {
                        margin: 2px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.emoji-palette.for-comment:not([class|="embed-theme"] *) {

    @media (max-width: 767px) {
        position: fixed;
        // JSで動的にパレットの幅と位置をいじっているんだけど、
        // スマホでは画面幅に一致させたいので important が必要
        right: auto!important;
        top: auto!important;
        bottom: 0!important;
        left: 0!important;
        width: 100vw !important;
        height: 70vh;
        animation: slideIn 0.3s ease;
    }

    & .main-section {
        @media (max-width: 767px)
        {
            height: auto;
            max-height: 95%;
        }
    }
}

.emoji-palette-background.for-comment:not([class|="embed-theme"] *) {
    &.open {
        @media (max-width: 767px) {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background-color: rgba(0, 0, 0, .5);
            z-index: 99;
        }
    }
}

.emoji-palette-background.for-comment {
    display: none;
}

.emoji-palette.for-comment {
    position: absolute;
    background-color: #fff;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    max-width: 100vw;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    display: none;
    z-index: 100;

    &.open {
        display: block;
    }

    & ul.emoji-category-nav-tab {
        list-style: none;
        display: flex;
        justify-content: space-around;
        padding: 0;
        margin-bottom: 12px;

        & li:not(.active) {
            cursor: pointer;
        }

        & li.active {
            border-bottom: 2px solid #000000;
        }
    }

    & .main-section {
        height: 200px;
        overflow-y: auto;

        & .frequently-used {
            & p {
                font-size: 8px;
            }

            & ul {
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                padding: 0;

                & li {
                    margin: 2px;
                }
            }
        }

        & .emoji-category-panels {
            & p {
                font-size: 8px;
            }

            & .emoji-panel {
                display: none;

                &.active {
                    display: block;
                }

                & ul {
                    list-style: none;
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    padding: 0;

                    & li {
                        margin: 2px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

// パレット上の絵文字のザイズ
.emoji-palette .main-section .twa {
    height: 2em;
    width: 2em;
    margin: 0.5em;
    vertical-align: -0.2em;
    background-size: 2em 2em;
}
