.markdown-toolbox-dialog-preview .panel-body {
    max-height: 300px;
    min-height: 100px;
    overflow: auto;
}

body.fullscreen-editor {
    position: fixed;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}

.md-code-editor.fullscreen {
    position: fixed;
    height: auto;
    width: 50%;
    top: 0;
    /* bottom / height to be justified by script */
    left: 0;
    resize: none;
    z-index: 1049;
    -webkit-overflow-scrolling: touch;
}

.dialog-area.fullscreen {
    position: fixed;
    height: auto;
    width: auto;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    z-index: 1049;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    border-left: 4px solid silver;
    background-color: white;
}

.dialog-area.fullscreen .markdown-toolbox-dialog-preview .panel-body {
    max-height: none;
}

.markdown_toolbox.fullscreen {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 50%;
    /* height to be justified by script */
    z-index: 1100;
    padding-left: 10px;
    padding-right: 10px;
    background-color: silver;
}

.fullscreen-close {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1050;
}

@media (max-width: 479px) {
    .md-code-editor.fullscreen {
        width: 100%;
        bottom: 50%;
    }
    .dialog-area.fullscreen {
        top: 50%;
        left: 0;
        border-top: 4px solid silver;
        border-left: none;
    }
    .markdown_toolbox.fullscreen {
        width: 100%;
        top: 50%;
        /* margin-top to be justified by script */
        bottom: auto;
    }
}
