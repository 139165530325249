/*!
 * bootstrap-tabs-x v1.3.5
 * http://plugins.krajee.com/tabs-x
 *
 * Krajee default Bootstrap 3.x styling for bootstrap-tabs-x.
 *
 * Author: Kartik Visweswaran
 * Copyright: 2014 - 2021, Kartik Visweswaran, Krajee.com
 *
 * Licensed under the BSD 3-Clause
 * https://github.com/kartik-v/bootstrap-tabs-x/blob/master/LICENSE.md
 */
.tab-align-center .nav-tabs > li, .tab-align-center .nav-pills > li,
.tab-align-right .nav-tabs > li, .tab-align-right .nav-pills > li {
    float: none;
    display: inline-block;
    *display: inline; /* ie7 fix */
    zoom: 1; /* hasLayout ie7 trigger */
}

.tabs-left .nav-tabs, .tabs-right .nav-tabs, .tabs-left .tab-content, .tabs-right .tab-content {
    height: 100%;
}

.tab-align-center .nav-tabs, .tab-align-center .nav-pills {
    text-align: center;
}

.tab-align-right .nav-tabs, .tab-align-right .nav-pills {
    text-align: right;
}

.tab-align-right .nav-tabs li:last-child {
    margin-right: -2px;
}

.tab-height-xs .tab-content {
    height: 135px !important;
    overflow: auto;
}

.tab-height-sm .tab-content {
    height: 195px !important;
    overflow: auto;
}

.tab-height-md .tab-content {
    height: 280px !important;
    overflow: auto;
}

.tab-height-lg .tab-content {
    height: 400px !important;
    overflow: auto;
}

.tab-content > .tab-pane,
.pill-content > .pill-pane {
    display: none;
}

.tab-content > .active,
.pill-content > .active {
    display: block;
}

.tabs-above > .nav-tabs > li > a:hover {
    border-color: #ddd #ddd #eee #ddd!important;
}

.tabs-above > .nav-tabs > .active > a,
.tabs-above > .nav-tabs > li.active > a:hover,
.tabs-above > .nav-tabs > li.active > a:focus {
    border-color: #ddd #ddd transparent #ddd!important;
}

.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
    border-bottom: 0;
}

.tabs-below > .nav-tabs {
    border-top: 1px solid #ddd;
}

.tabs-below > .nav-tabs > li {
    margin-top: -1px;
    margin-bottom: 0;
}

.tabs-below > .nav-tabs > li > a {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
}

.tabs-below > .nav-tabs > li > a:hover {
    border-color: #eee #ddd #ddd #ddd!important;
}

.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > li.active > a:hover,
.tabs-below > .nav-tabs > li.active > a:focus {
    border-color: transparent #ddd #ddd #ddd!important;
}

.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
    float: none;
}

.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
    margin-right: 0;
    margin-bottom: 3px;
}

.tabs-left > .nav-tabs {
    float: left;
    margin-right: 19px;
    border-right: 1px solid #ddd;
}

.tabs-left > .nav-tabs > li > a {
    margin-right: -1px;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
}

.tabs-left > .nav-tabs > li > a:hover {
    border-color: #ddd #eee #ddd #ddd!important;
}

.tabs-left > .nav-tabs > .active > a,
.tabs-left > .nav-tabs > li.active > a:hover,
.tabs-left > .nav-tabs > li.active > a:focus {
    border-color: #ddd transparent #ddd #ddd!important;
}

.tabs-right > .nav-tabs {
    float: right;
    border-left: 1px solid #ddd;
}

.tabs-right > .nav-tabs > li > a {
    margin-left: -1px;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}

.tabs-right > .nav-tabs > li > a:hover {
    border-color: #ddd #ddd #ddd #eee!important;
}

.tabs-right > .nav-tabs > .active > a,
.tabs-right > .nav-tabs > li.active > a:hover,
.tabs-right > .nav-tabs > li.active > a:focus {
    border-color: #ddd #ddd #ddd transparent!important;
}

.tab-content {
    padding: 10px;
}

.tabs-above.tab-bordered .tab-content {
    border: 1px solid #ddd;
    border-top: none;
    border-radius: 0 0 4px 4px;
}

.tabs-below.tab-bordered .tab-content {
    border: 1px solid #ddd;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
}

.tabs-left .nav-tabs, .tabs-right .nav-tabs, .tabs-left .tab-content, .tabs-right .tab-content {
    height: 100%;
}

.tabs-left .tab-content {
    border: none;
    border-left: 1px solid #ddd;
}

.tabs-right .tab-content {
    border: none;
    border-right: 1px solid #ddd;
}

.tabs-left.tab-bordered .tab-content {
    border: 1px solid #ddd;
    border-radius: 0 4px 4px 0;
}

.tabs-left.tab-bordered .tab-content .tab-pane, .tabs-right.tab-bordered .tab-content .tab-pane {
    margin-left: 0;
}

.tabs-left .nav-tabs, .tabs-right .nav-tabs {
    margin-left: 0;
    margin-right: 0;
}

.tabs-right.tab-bordered .tab-content {
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
}

.kv-tab-loading {
    background-image: url('../img/loading.gif');
    background-position: right 2px center;
    background-repeat: no-repeat;
    z-index: 15000;
    cursor: wait;
    opacity: 0.6;
}

/**
 * sideways tabs
 */
.tab-sideways .nav-tabs {
    margin-top: 51px;
    border: none;
    position: relative;
}

.tab-sideways.tabs-left .nav-tabs > li.active,
.tab-sideways.tabs-right .nav-tabs > li.active {
    border-top: 1px solid #fff;
}

.tab-sideways .nav-tabs > li > a {
    border-bottom: 1px solid #ddd;
    border-right-color: transparent;
    text-align: center;
    border-radius: 4px 4px 0px 0px;
}

.tab-sideways .nav-tabs .dropdown-menu {
    margin-top: 20px;
}

.tabs-right.tab-sideways .nav-tabs > li.active > a,
.tabs-right.tab-sideways .nav-tabs > li.active > a:hover,
.tabs-right.tab-sideways .nav-tabs > li.active > a:focus {
    border-radius: 4px 4px 0px 0px;
    border-color: #ddd;
    border-bottom: none !important;
}

.tabs-left.tab-sideways .nav-tabs > li.active > a,
.tabs-left.tab-sideways .nav-tabs > li.active > a:hover,
.tabs-left.tab-sideways .nav-tabs > li.active > a:focus {
    border-radius: 4px 4px 0px 0px;
    border-color: #ddd;
    border-bottom: none !important;
}

.tabs-right.tab-sideways .nav-tabs > li {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.tabs-left.tab-sideways .nav-tabs > li {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.tab-sideways .nav-tabs > li > a {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tab-sideways > .nav-tabs > li > a:hover {
    border-color: #ddd #ddd #eee #ddd!important;
}
.tab-sideways > .nav-tabs > .active > a,
.tab-sideways > .nav-tabs > li.active > a:hover,
.tab-sideways > .nav-tabs > li.active > a:focus {
    border-color: #ddd #ddd #fff #ddd!important;
}

/**
 * krajee tabs style
 * for left & right positioned tabs
 */
.tabs-krajee.tabs-left .nav-tabs, .tabs-krajee.tabs-right .nav-tabs {
    width: 129px;
}

.tabs-krajee.tabs-left .tab-content {
    margin-left: 128px;
}

.tabs-krajee.tabs-right .tab-content {
    margin-right: 128px;
}

.tabs-krajee.tab-sideways .nav-tabs > li {
    height: 20px;
    width: 120px;
    margin-bottom: 100px;
}

.tabs-krajee.tabs-left.tab-sideways .nav-tabs {
    left: -50px;
    margin-right: -75px;
}

.tabs-krajee.tabs-right.tab-sideways .nav-tabs {
    right: -59px;
    margin-left: -70px;
}

.tabs-krajee.tabs-left.tab-sideways .tab-content {
    margin-left: 41px;
}

.tabs-krajee.tabs-right.tab-sideways .tab-content {
    margin-right: 41px;
}